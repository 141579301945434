<template>
  <div :class="$style.actions">
    <router-link v-if="editLink" :to="editLink">
      <Icon name="edit" :class="$style.icon" />
    </router-link>
    <Icon
      v-if="editModal"
      name="edit"
      :class="$style.icon"
      @click.prevent="$emit('edit')"
    />
    <router-link v-if="viewInternalLink" :to="viewInternalLink">
      <Icon name="eye" :class="$style.icon" />
    </router-link>
    <router-link v-if="addLink" :to="addLink" @click.prevent="$emit('add')">
      <Icon name="plus" :class="$style.icon" />
    </router-link>
    <Icon
      v-if="addTrash"
      name="trash"
      :class="$style.icon"
      @click="$emit('delete')"
    />
    <a v-if="viewLink" :href="viewLink" target="_blank">
      <Icon name="eye" :class="$style.icon" />
    </a>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue'

export default {
  components: { Icon },
  props: {
    editLink: {
      type: String,
      default: ''
    },
    addLink: {
      type: String,
      default: ''
    },
    viewLink: {
      type: String,
      default: ''
    },
    viewInternalLink: {
      type: String,
      default: ''
    },
    addTrash: {
      type: Boolean,
      default: true
    },
    editModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.actions {
  display: flex;
  align-items: center;

  .icon {
    fill: $light-gray;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    margin-right: 1rem;
  }
}
</style>
